interface Audio {
  id: number;
  image: string;
  audio: string;
}

const initialAudios: Audio[] = [
  {
    id: 1,
    image: "/images/DJ1.webp",
    audio: "/audio/manifesto1.mp3",
  },
  {
    id: 2,
    image: "/images/DJ1.webp",
    audio: "/audio/01.mp3",
  },
  {
    id: 3,
    image: "/images/DJ1.webp",
    audio: "/audio/manifesto2.mp3",
  },
  {
    id: 4,
    image: "/images/DJ1.webp",
    audio: "/audio/about1.mp3",
  },
  {
    id: 5,
    image: "/images/DJ1.webp",
    audio: "/audio/02.mp3",
  },
  {
    id: 6,
    image: "/images/DJ2.webp",
    audio: "/audio/03.mp3",
  },
  {
    id: 7,
    image: "/images/DJ2.webp",
    audio: "/audio/about2.mp3",
  },
  {
    id: 8,
    image: "/images/DJ2.webp",
    audio: "/audio/mission1.mp3",
  },
  {
    id: 9,
    image: "/images/DJ2.webp",
    audio: "/audio/04.mp3",
  },
  {
    id: 10,
    image: "/images/DJ2.webp",
    audio: "/audio/mission2.mp3",
  },
  {
    id: 11,
    image: "/images/DJ3.webp",
    audio: "/audio/tokenomics1.mp3",
  },
  {
    id: 12,
    image: "/images/DJ3.webp",
    audio: "/audio/tokenomics2.mp3",
  },
  {
    id: 13,
    image: "/images/DJ3.webp",
    audio: "/audio/05.mp3",
  },
  {
    id: 14,
    image: "/images/DJ4.webp",
    audio: "/audio/rewards.mp3",
  },
  {
    id: 15,
    image: "/images/DJ5.webp",
    audio: "/audio/community.mp3",
  },
  {
    id: 16,
    image: "/images/DJ4.webp",
    audio: "/audio/Dance Floor Cat.mp3",
  },
  {
    id: 17,
    image: "/images/DJ4.webp",
    audio: "/audio/Digital Disconnect ext v2.mp3",
  },
  {
    id: 18,
    image: "/images/DJ4.webp",
    audio: "/audio/Digital Horizons ext v1.mp3",
  },
  {
    id: 19,
    image: "/images/DJ3.webp",
    audio: "/audio/Digital Horizons.mp3",
  },
  {
    id: 20,
    image: "/images/DJ3.webp",
    audio: "/audio/Drifting in Light.mp3",
  },
  {
    id: 21,
    image: "/images/DJ2.webp",
    audio: "/audio/Neon Dreams.mp3",
  },
  {
    id: 22,
    image: "/images/DJ1.webp",
    audio: "/audio/Whispering Signals ext v1.mp3",
  },
];

export function shuffleArray() {
  const array = [...initialAudios];
  for (let i = array.length - 1; i > 0; i--) {
    const j = Math.floor(Math.random() * (i + 1));
    [array[i], array[j]] = [array[j], array[i]];
  }
  return array;
}
