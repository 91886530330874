import React, { useEffect, useState } from "react";
import "./Home.css";

type HomeProps = {
  id: string;
};

export default function Home({ id }: HomeProps) {
  const imgUrls = Array.from({ length: 5 }).map(
    (_, i) => `/images/random-${i + 1}.png`
  );
  // const [backgroundImage, setBackgroundImage] = useState<string>("");
  const [bgImgIndex, setBgImgIndex] = useState<number>(0);

  // const handleCopyToClipboard = () => {
  //   navigator.clipboard
  //     .writeText(ca)
  //     .then(() => {
  //       console.log("Copied to clipboard:", ca);
  //       setShowPopup(true);
  //       setTimeout(() => setShowPopup(false), 2000);
  //     })
  //     .catch((error) => {
  //       console.error("Failed to copy:", error);
  //     });
  // };

  useEffect(() => {
    const interval = setInterval(() => {
      setBgImgIndex((prev) => (prev + 1) % imgUrls.length);
    }, 4000);

    return () => clearInterval(interval);
  }, []);

  return (
    <div className="Home" id={id}>
      <div className="Home-Banner">
        {imgUrls.map((url, i) => (
          <img
            key={url}
            src={url}
            alt={`Banner ${i + 1}`}
            className={
              i === bgImgIndex
                ? "Home-Banner-Image active"
                : "Home-Banner-Image"
            }
          />
        ))}
        <div className="Home-Banner-container">
          {/* <span className="Home-Banner-title">$MARCAT</span>
          <span className="Home-Banner-contents">
            The Token Powered by Your Passions
          </span> */}
          <img src="/images/text.png" alt="text" className="main-text" />
          <div className="Home-Banner-chain">
            <a href="/">
              <img
                src="/images/Base_Wordmark_White.svg"
                width={175}
                alt="base"
                className="Base_Logo"
              />
            </a>
          </div>
        </div>
      </div>
      <div className="Home-container">
        <div className="Home-container-TitleContainer">
          <span className="Home-container-Title">
            CRYPTO TRADING IS AN EMOTIONAL INVESTMENT!
          </span>
        </div>
        <div className="Home-container-Contents-Container">
          <div>
            <img src="/images/smoking.gif" alt="cats1" />
          </div>
          <div>
            <p className="Home-container-Contents">
              The coin market moves on the weight of our emotions. <br />
              <br /> When our hearts beat faster, the market responds. In fact,
              you could say that coin price charts are merely recordings of the
              emotional ups and downs of degen traders.
              <br />
              <br />
              Therefore, in the world of crypto, emotions are more critical than
              skill. Your emotions are your greatest asset.
            </p>
          </div>
        </div>
        <div className="Home-container-Community">
          <a
            className="Home-container-Community-Item"
            href="https://x.com/marcatcoin"
            target="_blank"
            rel="noreferrer noopener"
          >
            <img src="/images/communities/x.png" alt="x" />
          </a>
          <a
            className="Home-container-Community-Item"
            href="https://t.me/+ad4BZ7GSrHcwMTA1"
            target="_blank"
            rel="noreferrer noopener"
          >
            <img src="/images/communities/telegram.png" alt="telegram" />
          </a>
          <a
            className="Home-container-Community-Item"
            href="https://medium.com/@marcatcoin"
            target="_blank"
            rel="noreferrer noopener"
          >
            <img src="/images/communities/medium.png" alt="medium" />
          </a>
          <a className="Home-container-Community-Item">
            <img src="/images/communities/coingecko.png" alt="coingecko" />
          </a>
          <a className="Home-container-Community-Item">
            <img
              src="/images/communities/coinmarketcap.png"
              alt="coinmarketcap"
            />
          </a>
          <a
            className="Home-container-Community-Item"
            href="https://dexscreener.com/base/0x55f20c24b719f396c8f88d542ddae6ed4c4e4151"
            target="_blank"
            rel="noreferrer noopener"
          >
            <img src="/images/communities/dexscreener.png" alt="dexscreener" />
          </a>
          <a
            className="Home-container-Community-Item"
            href="https://www.dextools.io/app/en/base/pair-explorer/0x55f20c24b719f396c8f88d542ddae6ed4c4e4151?t=1723116898843"
            target="_blank"
            rel="noreferrer noopener"
          >
            <img src="/images/communities/dextools.png" alt="dextools" />
          </a>
          <a
            className="Home-container-Community-Item"
            href="https://unibot.app/x?chain=base&token=0x5E4CCd23a6cD0a5036a391aacb5Bc65A19067aeF"
            target="_blank"
            rel="noreferrer noopener"
          >
            <img src="/images/communities/unibot.png" alt="unibot" />
          </a>
          <a
            className="Home-container-Community-Item"
            href="https://www.geckoterminal.com/base/pools/0x55f20c24b719f396c8f88d542ddae6ed4c4e4151"
            target="_blank"
            rel="noreferrer noopener"
          >
            <img
              src="/images/communities/geckoterminal.png"
              alt="geckoterminal"
            />
          </a>
          <a
            className="Home-container-Community-Item"
            href="https://www.youtube.com/channel/UCAZK8xGJ1sCtrTBCVrB_MeQ"
            target="_blank"
            rel="noreferrer noopener"
          >
            <img src="/images/communities/youtube.png" alt="youtube" />
          </a>
          <a
            className="Home-container-Community-Item"
            href="https://flooz.xyz/trade/0x5e4ccd23a6cd0a5036a391aacb5bc65a19067aef/base/marcatcoin/marcat"
            target="_blank"
            rel="noreferrer noopener"
          >
            <img src="/images/communities/flooz.png" alt="flooz" />
          </a>
          <a
            className="Home-container-Community-Item"
            href="https://dune.com/workspace/u/marcatcoin/library"
            target="_blank"
            rel="noreferrer noopener"
          >
            <img src="/images/communities/dune.png" alt="dune" />
          </a>
        </div>
      </div>
    </div>
  );
}
