import React from "react";
import "./HowToBuy.css";
import Widget from "./SwapWidget";

type HowToBuyProps = {
  id: string;
};

export default function HowToBuy({ id }: HowToBuyProps) {
  return (
    <div className="HowToBuy" id={id}>
      <div className="HowToBuy-container">
        <div className="HowToBuy-container-TitleContainer">
          <div className="eclipse-right" />
          <span className="HowToBuy-container-Title">Buy $MARCAT</span>
        </div>
        <div className="HowToBuy-container-Contents-Container">
          <div className="HowToBuy-container-Contents-Left">
            <div>
              <div
                style={{
                  display: "flex",
                  alignItems: "center",
                  flexDirection: "row",
                  gap: "4px",
                }}
              >
                <span style={{ fontSize: "12px" }}>
                  CoinMarketCat is powered by
                </span>
                <img
                  src="/images/Base_Wordmark_White.svg"
                  width={50}
                  alt="base"
                />
              </div>

              <p>
                To buy CoinMarketCat ($MARCAT), first bridge your Ethereum to
                the Base network.
              </p>
            </div>
            <div className="HowToBuy-container-Contents-Left-Second">
              <div className="HowToBuy-container-Contents-Left-NumberBox">
                <span>1</span>
              </div>

              <span className="HowToBuy-container-Contents-Left-Title">
                Do you already have BASE ETH in your wallet?
              </span>
              <p>
                You don't need to do anything else. Just purchase the desired
                amount of $MARCAT from the right-hand window.
              </p>
            </div>
            <div className="HowToBuy-container-Contents-Left-Second">
              <div className="HowToBuy-container-Contents-Left-NumberBox">
                <span>2</span>
              </div>

              <span className="HowToBuy-container-Contents-Left-Title">
                Do you need to convert ETH to BASE ETH?
              </span>
              <p>Follow the link below to convert your ETH to BASE ETH.</p>
              <a
                href="https://bridge.base.org/deposit"
                target="_blank"
                rel="noreferrer"
              >
                https://bridge.base.org/deposit
              </a>
            </div>
          </div>
          <div className="HowToBuy-container-Contents-Right">
            <Widget />
          </div>
        </div>
      </div>
    </div>
  );
}
