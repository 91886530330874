import React, { useState, useEffect, useMemo } from "react";
import Header from "../../components/Header/Header";
import Home from "../../components/Home/Home";
import About from "../../components/About/About";
import Mission from "../../components/Mission/Mission";
import Tokenomics from "../../components/Tokenomics/Tokenomics";
import Reward from "../../components/Reward/Reward";
import HowToBuy from "../../components/ToBuy/HowToBuy";
import Community from "../../components/Community/Community";
import Faq from "../../components/Faq/Faq";
import Footer from "../../components/Footer/Footer";
import AudioPlayer from "../../components/AudioPlayer/AudioPlayer";
import KeyboardArrowUpIcon from "@mui/icons-material/KeyboardArrowUp";
import "./Main.css";
import { shuffleArray } from "../../helpers";

function Main() {
  const [showScrollButton, setShowScrollButton] = useState(false);
  const shuffledAudios = useMemo(() => shuffleArray(), []);

  useEffect(() => {
    const handleScroll = () => {
      if (window.scrollY > 200) {
        setShowScrollButton(true);
      } else {
        setShowScrollButton(false);
      }
    };

    window.addEventListener("scroll", handleScroll);

    return () => {
      window.removeEventListener("scroll", handleScroll);
    };
  }, []);

  return (
    <div className="Main" id="Main">
      <Header />
      <Home id="home" />
      <About id="about" />
      <Mission id="mission" />
      <Tokenomics id="tokenomics" />
      <Reward id="reward" />
      <HowToBuy id="howtobuy" />
      <Community id="community" />
      <Faq id="faq" />
      <Footer />
      <AudioPlayer audios={shuffledAudios} />
      {showScrollButton && (
        <div
          className="scroll-to-top"
          onClick={() => window.scrollTo({ top: 0, behavior: "smooth" })}
        >
          <KeyboardArrowUpIcon />
        </div>
      )}
    </div>
  );
}

export default Main;
