import React from "react";
import "./Reward.css";

type RewardProps = {
  id: string;
};

export default function Reward({ id }: RewardProps) {
  return (
    <div className="Reward" id={id}>
      <div className="Reward-container">
        <div className="Reward-container-TitleContainer">
          <div className="eclipse-left" />
          <span className="Reward-container-Title">BONUS REWARDS</span>
        </div>
        <div className="Reward-container-Contents-Container">
          <div className="Reward-container-Contents-Banner">
            <div className="Reward-container-Contents-Banner-Title">
              <p>
                EMOTIONAL INVESTORS <br /> DESERVE SPECIAL REWARDS!
              </p>
            </div>
            <button className="Reward-container-Contents-Banner-Button">
              <img
                src="/images/OpenSea-Full-Logo.svg"
                width={138}
                alt="opensea"
              />
            </button>
          </div>
          <div className="Reward-container-Contents-ImageContainer">
            <img src="/images/V1.webp" alt="V1" />
            <img src="/images/V2.webp" alt="V1" />
            <img src="/images/V3.webp" alt="V1" />
          </div>
          <div className="Reward-container-Contents-Footer">
            <span>Emotional investing truly deserves rewards!</span>
            <p>
              Holding $MARCAT entitles you to unique Emotion Expression NFTs,
              capturing the essence of the ‘80s and ‘90s. Discover the fun of
              uncovering hidden cultural codes within each artwork.{" "}
            </p>
            <br />
            <span>Airdrops will proceed after the snapshot.</span>
          </div>
        </div>
      </div>
    </div>
  );
}
