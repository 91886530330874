import React from "react";
import "./Footer.css";
import { HashLink } from "react-router-hash-link";

const navItems = [
  { name: "About", path: "#about", key: "about" },
  { name: "Mission", path: "#mission", key: "mission" },
  { name: "Tokenomics", path: "#tokenomics", key: "token" },
  { name: "Rewards", path: "#reward", key: "reward" },
  { name: "Buy", path: "#howtobuy", key: "howtobuy" },
  { name: "Q&A", path: "#faq", key: "faq" },
];

export default function Footer() {
  return (
    <footer className="Footer">
      <div className="Footer-container">
        <a
          style={{
            display: "flex",
            alignItems: "center",
            fontWeight: "bold",
            gap: "10px",
            fontSize: "18px",
          }}
          href="/"
        >
          <img src="/images/logo-big.png" alt="Logo" className="Footer-logo" />
          MarcatCoin
        </a>

        <nav className="Footer-nav">
          {navItems.map((item, index) => (
            <HashLink
              key={item.key}
              smooth
              to={item.path}
              className="Footer-navbar-item"
            >
              {item.name}
            </HashLink>
          ))}
          <a className="Footer-navbar-item" href="mailto:marcatcoin@gmail.com">
            Contact
          </a>
        </nav>
        <a href="https://x.com/marcatcoin" target="_blank" rel="noreferrer">
          <img src="/images/x-logo-white.png" width={50} alt="twitter" />
        </a>
      </div>
    </footer>
  );
}
