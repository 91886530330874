import React from "react";
import "./Community.css";

type CommunityProps = {
  id: string;
};

export default function Community({ id }: CommunityProps) {
  return (
    <div className="Community" id={id}>
      <div className="Community-container">
        <div className="Community-container-TitleContainer">
          <div className="eclipse-right" />
          <span className="Community-container-Title">COMMUNITY</span>
        </div>
        <div className="Community-container-Contents-Container">
          <div style={{ filter: "brightness(0.9)", position: "relative" }}>
            <div className="Community-container-Contents-ImageContainer">
              <img src="/images/community01.png" alt="community1" />
              <img src="/images/community02.png" alt="community2" />
              <img src="/images/community03.png" alt="community3" />
              <img src="/images/cat1.gif" alt="cat1" />
              <img src="/images/community05.png" alt="community5" />
              <img src="/images/cat2.gif" alt="cat2" />
              <img src="/images/community07.png" alt="community7" />
              <img src="/images/community08.png" alt="community8" />
            </div>

            <div className="Community-container-Contents-Absolute">
              <span>JOIN US TODAY!</span>
              <p>
                Buy some $MARCAT and become our family. Let’s make a huge family
                together.{" "}
              </p>
            </div>
          </div>
          <div className="Home-container-Community">
            <a
              className="Home-container-Community-Item"
              href="https://x.com/marcatcoin"
              target="_blank"
              rel="noreferrer noopener"
            >
              <img src="/images/communities/x.png" alt="x" />
            </a>
            <a
              className="Home-container-Community-Item"
              href="https://t.me/+ad4BZ7GSrHcwMTA1"
              target="_blank"
              rel="noreferrer noopener"
            >
              <img src="/images/communities/telegram.png" alt="telegram" />
            </a>
            <a
              className="Home-container-Community-Item"
              href="https://medium.com/@marcatcoin"
              target="_blank"
              rel="noreferrer noopener"
            >
              <img src="/images/communities/medium.png" alt="medium" />
            </a>
            <a className="Home-container-Community-Item">
              <img src="/images/communities/coingecko.png" alt="coingecko" />
            </a>
            <a className="Home-container-Community-Item">
              <img
                src="/images/communities/coinmarketcap.png"
                alt="coinmarketcap"
              />
            </a>
            <a
              className="Home-container-Community-Item"
              href="https://dexscreener.com/base/0x55f20c24b719f396c8f88d542ddae6ed4c4e4151"
              target="_blank"
              rel="noreferrer noopener"
            >
              <img
                src="/images/communities/dexscreener.png"
                alt="dexscreener"
              />
            </a>
            <a
              className="Home-container-Community-Item"
              href="https://www.dextools.io/app/en/base/pair-explorer/0x55f20c24b719f396c8f88d542ddae6ed4c4e4151?t=1723116898843"
              target="_blank"
              rel="noreferrer noopener"
            >
              <img src="/images/communities/dextools.png" alt="dextools" />
            </a>
            <a
              className="Home-container-Community-Item"
              href="https://unibot.app/x?chain=base&token=0x5E4CCd23a6cD0a5036a391aacb5Bc65A19067aeF"
              target="_blank"
              rel="noreferrer noopener"
            >
              <img src="/images/communities/unibot.png" alt="unibot" />
            </a>
            <a
              className="Home-container-Community-Item"
              href="https://www.geckoterminal.com/base/pools/0x55f20c24b719f396c8f88d542ddae6ed4c4e4151"
              target="_blank"
              rel="noreferrer noopener"
            >
              <img
                src="/images/communities/geckoterminal.png"
                alt="geckoterminal"
              />
            </a>
            <a
              className="Home-container-Community-Item"
              href="https://www.youtube.com/channel/UCAZK8xGJ1sCtrTBCVrB_MeQ"
              target="_blank"
              rel="noreferrer noopener"
            >
              <img src="/images/communities/youtube.png" alt="youtube" />
            </a>
            <a
              className="Home-container-Community-Item"
              href="https://flooz.xyz/trade/0x5e4ccd23a6cd0a5036a391aacb5bc65a19067aef/base/marcatcoin/marcat"
              target="_blank"
              rel="noreferrer noopener"
            >
              <img src="/images/communities/flooz.png" alt="flooz" />
            </a>
            <a
              className="Home-container-Community-Item"
              href="https://dune.com/workspace/u/marcatcoin/library"
              target="_blank"
              rel="noreferrer noopener"
            >
              <img src="/images/communities/dune.png" alt="dune" />
            </a>
          </div>
        </div>
      </div>
    </div>
  );
}
