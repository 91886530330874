import React, { useEffect, useState, useMemo } from "react";
import "./Mission.css";

type MissionProps = {
  id: string;
};

export default function Mission({ id }: MissionProps) {
  const images_ = useMemo(
    () => [
      { src: "/video/OurMission-1.webp", duration: 4000 },
      { src: "/video/OurMission-2.webp", duration: 4000 },
      { src: "/video/OurMission-3.webp", duration: 8000 },
      { src: "/video/OurMission-4.webp", duration: 4000 },
      { src: "/video/OurMission-5.webp", duration: 4000 },
    ],
    []
  );

  const [currentIndex, setCurrentIndex] = useState(0);

  useEffect(() => {
    const { duration } = images_[currentIndex];
    const timeout = setTimeout(() => {
      setCurrentIndex((prevIndex) => (prevIndex + 1) % images_.length);
    }, duration);

    return () => clearTimeout(timeout); // Clean up timeout on unmount
  }, [currentIndex, images_]);

  return (
    <div className="Mission" id={id}>
      <div className="Mission-container">
        <div className="Mission-container-TitleContainer">
          <div className="eclipse-left" />
          <span className="Mission-container-Title">OUR MISSION</span>
        </div>
        <div className="Mission-container-Contents-Container">
          <div className="Mission-container-Contents-Left">
            <div className="Mission-container-Contents-Left-Main">
              <div style={{ display: "flex", flexDirection: "column" }}>
                <span style={{ fontSize: "24px" }}>The Currency of</span>
                <span>WEB 3.0</span>
              </div>
            </div>
            <div className="Mission-container-Contents-Left-Sub">
              <img
                src={images_[currentIndex].src}
                width={410}
                alt="missions"
                className="mission_img"
              />
            </div>
          </div>

          <div className="Mission-container-Contents-Right">
            <div className="Mission-container-Contents-Right-Main">
              <p>
                WE’LL LEAVE FOOTPRINTS <br /> IN THE ERA OF WEB 3.0.
              </p>
            </div>
            <div className="Mission-container-Contents-Right-Sub">
              <div className="Mission-container-Contents-Right-Sub-Contents">
                <p>
                  In Web 3.0, everyone deserves rewards for their online
                  activities. Whether visiting a site, joining a community, or
                  watching content, the earning opportunities are limitless.
                  <br />
                  <br />
                  This digital age brings models like play-to-earn,
                  join-to-earn, and do-to-earn. Our mission is to make{" "}
                  <b>$MARCAT</b> the primary currency for these activities
                  across various do-to-earn platforms and games, including our
                  unique marketplace. <br />
                  <br />
                  See more details in the Q&A below.
                </p>
              </div>
            </div>
          </div>
        </div>
        <div className="Mission-container-Footer">
          <div className="Mission-container-Footer-Title">
            <span>Glimpse of Web 3.0.</span>
          </div>
          <div className="Mission-container-Footer-Contents">
            <div>
              <img src="/images/GIF_v1.gif" alt="gif_v1" />
            </div>
            <div>
              <img src="/images/GIF_v2.gif" alt="gif_v2" />
            </div>
            <div>
              <img src="/images/GIF_v3.gif" alt="gif_v3" />
            </div>
            <div>
              <img src="/images/GIF_v4.gif" alt="gif_v4" />
            </div>
            <div>
              <img src="/images/GIF_v5.gif" alt="gif_v5" />
            </div>
            <div>
              <img src="/images/GIF_v6.gif" alt="gif_v6" />
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}
