import React from "react";
import Accordion from "@mui/material/Accordion";
import AccordionSummary from "@mui/material/AccordionSummary";
import AccordionDetails from "@mui/material/AccordionDetails";
import Typography from "@mui/material/Typography";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import { styled } from "@mui/material/styles";
import "./Faq.css";

type FAQProps = {
  id: string;
};

const CustomAccordion = styled(Accordion)(({ theme }) => ({
  backgroundColor: "#F4F4F4",
  "&.Mui-expanded": {
    margin: "0",
  },
}));

const faqs = [
  {
    question: "What makes $MARCAT unique?",
    answer: `<b>$MARCAT</b> is not just another meme token; it’s built with a strong community focus, unique rewards, and integrated NFT airdrops to enhance engagement and value. We believe that Web 3.0 will quickly become an integral part of our lives. \n\nThis digital age brings models like play-to-earn, join-to-earn, and do-to-earn. Our mission is to make <b>$MARCAT</b> the primary currency for these activities across various do-to-earn platforms and games, including our unique marketplace.`,
  },
  {
    question: "What are the future plans for $MARCAT?",
    answer: `Our goal aligns with yours: to increase the value of <b>$MARCAT</b> and make our associated IPs famous, creating an exciting and fresh ecosystem.

      <b>Community Engagement and Growth:</b>

      <li> Build a robust platform where users are rewarded for various online interactions.</li>
      <li> Foster a vibrant community that thrives on active participation and emotional investment. </li>

      <b>Innovative Reward Systems:</b>

      <li> Introduce multiple reward mechanisms including money, tokens, NFTs, and points.</li>
      <li> Develop user-centric experiences that boost engagement and provide tangible rewards.</li>

      <b>Integration with Web 3.0 Technologies:</b>

      <li> Seamlessly integrate with the latest Web 3.0 technologies to enhance user experience.</li>
      <li> Ensure our platform is at the cutting edge of technological advancements, offering fast and secure transactions.</li>

      <b>Emotional and Financial Growth:</b>
      
      <li> Create a platform that not only boosts your emotional well-being but also provides significant financial rewards.</li>
      <li> Promote activities that enhance community spirit and personal growth.</li>

      <b>Long-Term Sustainability:</b>

      <li> Focus on creating a sustainable ecosystem where rewards and growth are balanced.</li>
      <li> Implement strategies that ensure the long-term viability and success of the $MARCAT token.</li>
  `,
  },
  {
    question: "Our Commitment",
    answer: `We are committed to realizing a future where your online activities are both rewarding and fulfilling. The era of Web 3.0 is not a distant dream; it’s here and now. Join us on this journey and grow your emotions, investments, and rewards with <b>$MARCAT</b>. 
    
    Together, we can create a brighter, more rewarding digital future.`,
  },
  {
    question: "When will the NFT airdrop take place?",
    answer:
      "The NFT airdrop will take place after the token sale ends. Specific dates will be announced on our official channels. We will airdrop 9,500 unique NFTs to <b>$MARCAT</b> holders.",
  },
  {
    question: "What’s the benefit of using the bridge network?",
    answer:
      "Using the bridge network allows you to transfer your Ethereum to the BASE network, enabling faster and cheaper transactions. This ensures that you can buy, sell, and trade <b>$MARCAT</b> more efficiently while reducing transaction fees and wait times compared to the Ethereum mainnet.",
  },
  {
    question: "What wallets can I use to store $MARCAT?",
    answer:
      "<b>$MARCAT</b> can be stored in any wallet that supports the BASE network, such as MetaMask, Coinbase Wallet, Trust Wallet, and other popular Ethereum-compatible wallets.",
  },
  {
    question: "Do I need to bridge my ETH to the BASE network first?",
    answer:
      "Yes, to purchase <b>$MARCAT</b>, you need to bridge your ETH to the BASE network. Follow our guide for detailed instructions.",
  },
];

export default function Faq({ id }: FAQProps) {
  return (
    <div className="FAQ" id={id}>
      <div className="FAQ-container">
        <div className="FAQ-container-TitleContainer">
          <div className="eclipse-left"></div>
          <span className="FAQ-container-Title">Q&A</span>
        </div>
        {faqs.map((faq, index) => (
          <CustomAccordion key={index}>
            <AccordionSummary
              expandIcon={<ExpandMoreIcon />}
              aria-controls={`panel${index}-content`}
              id={`panel${index}-header`}
            >
              <Typography
                style={{
                  fontSize: "20px",
                  fontWeight: "bold",
                  fontFamily: "Montserrat",
                }}
              >
                {faq.question}
              </Typography>
            </AccordionSummary>
            <AccordionDetails>
              <Typography
                style={{
                  fontSize: "16px",
                  fontWeight: "500",
                  fontFamily: "Montserrat",
                  whiteSpace: "pre-line",
                }}
                dangerouslySetInnerHTML={{ __html: faq.answer }}
              />
            </AccordionDetails>
          </CustomAccordion>
        ))}
      </div>
    </div>
  );
}
