import React, { useEffect, useState } from "react";
import "./Header.css";
import MenuIcon from "@mui/icons-material/Menu";
import Dialog from "@mui/material/Dialog";
import DialogContent from "@mui/material/DialogContent";
import { HashLink } from "react-router-hash-link";

const navItems = [
  { name: "About", path: "#about", key: "about" },
  { name: "Mission", path: "#mission", key: "mission" },
  { name: "Tokenomics", path: "#tokenomics", key: "token" },
  { name: "Rewards", path: "#reward", key: "reward" },
];

const navItems_Mobile = [
  { name: "About", path: "#about", key: "about" },
  { name: "Mission", path: "#mission", key: "mission" },
  { name: "Tokenomics", path: "#tokenomics", key: "token" },
  { name: "Rewards", path: "#reward", key: "reward" },
  { name: "Buy", path: "#howtobuy", key: "howtobuy" },
  { name: "Q&A", path: "#faq", key: "faq" },
];

export default function Header() {
  const [isMenuOpen, setIsMenuOpen] = useState(false);
  const [innerWidth, setInnerWidth] = useState(window.innerWidth);

  useEffect(() => {
    const handleResize = () => {
      setInnerWidth(window.innerWidth);
    };

    window.addEventListener("resize", handleResize);

    // Cleanup the event listener on component unmount
    return () => {
      window.removeEventListener("resize", handleResize);
    };
  }, []);

  const toggleMenu = () => {
    setIsMenuOpen(!isMenuOpen);
  };

  return (
    <header className="Header">
      <div className="Header-navbar-start">
        <a href="/">
          <div className="Header-Logo-Container">
            <img src="/images/logo-big.png" width={36} alt="logo"></img>
            <span className="Header-Logo-Text">MarcatCoin</span>
          </div>
        </a>

        {innerWidth > 900 && (
          <div className="Header-navbar-Link">
            {navItems.map((item, index) => (
              <HashLink
                key={item.key}
                smooth
                to={item.path}
                className="Header-navbar-item"
              >
                {item.name}
              </HashLink>
            ))}
          </div>
        )}
        <div className="Header-navbar-end">
          <span className="Header-navbar-btn">
            <HashLink smooth to={"#howtobuy"}>
              BUY NOW
            </HashLink>
          </span>
          {innerWidth < 900 && (
            <div className="Header-Menu" onClick={toggleMenu}>
              <MenuIcon
                style={{ color: "black", width: "42px", height: "42px" }}
              />
            </div>
          )}
        </div>
      </div>

      <Dialog open={isMenuOpen} onClose={toggleMenu} fullWidth maxWidth="xs">
        <DialogContent>
          {navItems_Mobile.map((item, index) => (
            <div style={{ padding: "0.5rem", fontSize: "20px" }} key={item.key}>
              <HashLink
                key={item.key}
                smooth
                to={item.path}
                className="Header-menu-item"
                onClick={toggleMenu}
              >
                {item.name}
              </HashLink>
            </div>
          ))}
        </DialogContent>
      </Dialog>
    </header>
  );
}
