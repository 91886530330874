import React from "react";
import "./About.css";
import { HashLink } from "react-router-hash-link";

type AboutProps = {
  id: string;
};

export default function About({ id }: AboutProps) {
  return (
    <div className="About" id={id}>
      <div className="About-container">
        <div className="About-container-TitleContainer">
          <div className="eclipse-right" />
          <span className="About-container-Title">ABOUT</span>
        </div>
        <div className="About-container-Contents-Container">
          <div className="About-container-Contents-Left">
            <div className="About-container-Contents-Left-Main">
              <div style={{ display: "flex", flexDirection: "column" }}>
                <span>WE TREATS</span>
                <span>emotions</span>
                <span>AS ASSETS.</span>
              </div>
              <p>
                We understand how deep-seated emotions of investors can move the
                market, and we aim to leverage this to enhance the value of our
                coin. <br />
                <br />
                Invest with your emotions! Your crypto trading prowess isn’t
                that important. <br />
                <br />
                The moment you hold $MARCAT, you join the elite ranks of
                emotional investors.
              </p>
            </div>
          </div>

          <div className="About-container-Contents-Right">
            <div className="About-container-Contents-Right-Main">
              <img src="/images/cmc.svg" width={60} alt="cmc" />
            </div>
            <div className="About-container-Contents-Right-Sub">
              <img
                src="/images/cmc_200x465_1.webp"
                width={200}
                className="Cat_video"
                alt="about_cat"
              />
              {/* <img src="/video/CMC_367x630.webp" alt="animated webp" /> */}
            </div>
          </div>
        </div>
        <div className="About-container-Contents-Left-Sub">
          <HashLink smooth to={"#howtobuy"}>
            <img
              src="/images/button.svg"
              width={450}
              alt="buy"
              className="buy_btn"
            />
          </HashLink>
        </div>
        <div className="About-container-Footer">
          <span>EMOTIONAL TRADING IS THE KEY TO VICTORY.</span>
        </div>
      </div>
    </div>
  );
}
