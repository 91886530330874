import React, { useEffect, useRef, useState } from "react";
import PauseIcon from "@mui/icons-material/Pause";
import PlayArrowIcon from "@mui/icons-material/PlayArrow";
import "./AudioPlayer.css";

interface Audio {
  id: number;
  image: string;
  audio: string;
}

interface AudioPlayerProps {
  audios: Audio[];
}

const AudioPlayer: React.FC<AudioPlayerProps> = ({ audios }) => {
  const [currentAudioIndex, setCurrentAudioIndex] = useState(0);
  const [isPlaying, setIsPlaying] = useState(false);
  const audioRef = useRef<HTMLAudioElement | null>(null);
  const [innerWidth, setInnerWidth] = useState(window.innerWidth);

  useEffect(() => {
    const handleResize = () => {
      setInnerWidth(window.innerWidth);
    };

    window.addEventListener("resize", handleResize);

    return () => {
      window.removeEventListener("resize", handleResize);
    };
  }, []);

  useEffect(() => {
    if (audioRef.current) {
      audioRef.current.src = audios[currentAudioIndex].audio;
      if (isPlaying) {
        audioRef.current.play();
      }
    }
  }, [audios, currentAudioIndex]);

  const handleAudioEnded = () => {
    setTimeout(() => {
      if (currentAudioIndex < audios.length - 1) {
        setCurrentAudioIndex(currentAudioIndex + 1);
      } else {
        setCurrentAudioIndex(0);
      }
    }, 2000); // 2초 딜레이
  };

  const handleLoadedData = () => {
    if (audioRef.current && isPlaying) {
      audioRef.current.play();
    }
  };

  const togglePlayPause = () => {
    if (audioRef.current) {
      if (isPlaying) {
        audioRef.current.pause();
      } else {
        audioRef.current.play();
      }
      setIsPlaying(!isPlaying);
    }
  };

  return (
    <>
      <div className="audio-player">
        <img
          src={audios[currentAudioIndex].image}
          alt="audio"
          className="audio-player-image"
        />
        <div className="audio-controller">
          <div onClick={togglePlayPause} className="audio-player-button">
            {isPlaying ? (
              <PauseIcon sx={{ fontSize: "xxx-large", color: "white" }} />
            ) : (
              <PlayArrowIcon
                style={{ fontSize: "xxx-large", color: "white" }}
              />
            )}
          </div>
        </div>
        <audio
          ref={audioRef}
          controls={innerWidth < 900 ? true : false}
          onEnded={handleAudioEnded}
          onLoadedData={handleLoadedData}
        />
      </div>
    </>
  );
};

export default AudioPlayer;
