import React, { useState, useEffect, useRef } from "react";

export default function Widget() {
  const [isVisible, setIsVisible] = useState(false);
  const iframeRef = useRef<HTMLDivElement>(null);

  useEffect(() => {
    const observer = new IntersectionObserver(
      (entries) => {
        const entry = entries[0];
        if (entry.isIntersecting) {
          setIsVisible(true);
          observer.disconnect();
        }
      },
      {
        root: null,
        threshold: 1,
      }
    );

    const currentIframeRef = iframeRef.current;

    if (currentIframeRef) {
      observer.observe(currentIframeRef);
    }

    return () => {
      if (currentIframeRef) {
        observer.unobserve(currentIframeRef);
      }
    };
  }, []);

  return (
    <div ref={iframeRef} style={{ height: "806px" }}>
      {isVisible ? (
        <iframe
          title="uniswap"
          src={`https://app.uniswap.org/swap?chain=base&inputCurrency=ETH&outputCurrency=0x5E4CCd23a6cD0a5036a391aacb5Bc65A19067aeF`}
          height="806px"
          width="100%"
          style={{
            border: "none",
            overflow: "hidden",
            boxShadow: "none",
            filter: "blur(0px)",
          }}
        />
      ) : null}
    </div>
  );
}
