import React, { useEffect, useState } from "react";
import ContentCopyIcon from "@mui/icons-material/ContentCopy";
import "./Tokenomics.css";

type TokenomicsProps = {
  id: string;
};

export default function Tokenomics({ id }: TokenomicsProps) {
  const [ca, setCA] = useState("");
  const [showPopup, setShowPopup] = useState(false);

  useEffect(() => {
    setCA("0x5E4CCd23a6cD0a5036a391aacb5Bc65A19067aeF");
  }, []);

  const handleCopyToClipboard = () => {
    navigator.clipboard
      .writeText(ca)
      .then(() => {
        console.log("Copied to clipboard:", ca);
        setShowPopup(true);
        setTimeout(() => setShowPopup(false), 1500);
      })
      .catch((error) => {
        console.error("Failed to copy:", error);
      });
  };
  return (
    <div className="Tokenomics" id={id}>
      <div className="Tokenomics-container">
        <div className="Tokenomics-container-TitleContainer">
          <div className="eclipse-right" />
          <span className="Tokenomics-container-Title">TOKENOMICS</span>
        </div>
        <div className="Tokenomics-container-Contents-Container">
          <div className="Tokenomics-container-Contents-Left">
            <div className="Tokenomics-container-Contents-Left-Main">
              <img src="/images/tokenomics.png" width={313} alt="token" />
              <p>
                No Taxes, liquidity locked, <br />
                contract ownership renounced.
              </p>
            </div>
          </div>
          <div className="Tokenomics-container-Contents-Right">
            <div className="Tokenomics-container-Contents-Right-Main">
              <div style={{ display: "flex", flexDirection: "column" }}>
                <span>Tokenomics Guaranteed By </span>
                <span>Math And Science For Fair Play!</span>
              </div>
            </div>
            <div className="Tokenomics-container-Contents-Right-Sub">
              <span className="Tokenomics-container-Contents-Right-Sub-Title">
                Total Supply: 999,999,999
              </span>
              <div className="Tokenomics-container-Contents-Right-Sub-Details">
                <div>
                  <span>70%</span>Locked LP
                </div>
                <div>
                  <span>10%</span>Marketing
                </div>
                <div>
                  <span>10%</span>Airdrop
                </div>
                <div>
                  <span>10%</span>Development
                </div>
              </div>
            </div>
          </div>
        </div>
        <div className="Tokenomics-container-Footer">
          <span style={{ fontWeight: "500", whiteSpace: "pre" }} className="CA">
            CA: {ca}
          </span>
          <div
            onClick={handleCopyToClipboard}
            style={{
              display: "flex",
              position: "relative",
              alignItems: "center",
              cursor: "pointer",
            }}
          >
            <ContentCopyIcon
              style={{ color: "#333333", fontSize: "22px", marginLeft: "8px" }}
            />
            {showPopup && (
              <div className="popup">
                <p style={{ fontSize: "16px", margin: 0, padding: "8px" }}>
                  Copied!
                </p>
              </div>
            )}
          </div>
        </div>
      </div>
    </div>
  );
}
